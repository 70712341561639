<template>
    <!-- 分拣打印errorsorting -->
    <div class="sorting-container">
        <div class="leftsorting">
            <logotop></logotop>
            <div class="sortingformbox">
               <a-form-model layout="inline" class="sortingForm" style="width:100%">
                   <div class="rowsortingform">
                       <div>
                           <a-form-model-item class="itemFormItem">
                              <a-button class="back-btn bgBlue" size="large" type="primary" style="padding:0px 28px;" @click="go_back">
                                <a-icon type="arrow-left" /> 返回
                              </a-button>
                           </a-form-model-item>
                       
                       </div>
                       <div>
                         
                       </div>
                     
                   </div>
                  
               </a-form-model>
           </div>
        </div>
       
        </div>
        
    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo 
  import sortingform from "@/components/sortingform"; //顶部查询
  import classification from "@/components/classification"; //顶部查询
  import productTab from "@/components/productTab"; //顶部查询
  
  export default {
    name: "sorting",
    components: {
       logotop,
       sortingform,
       classification,
       productTab
        
     },
    data() {
      return {
        activeKey: ['1'],
        customStyle:
               'background: #fff;color: #010101;box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);font-size: 23px;font-weight: 600;bordedr:none;overflow: hidden',
       classifylist:[{key:'',name:'全部'},{key:1,name:'肉类'},{key:2,name:'水产海鲜'},{key:3,name:'农家蔬菜'}],      
       productList:[{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,outstock:0},{status:1,statusname:'已完成',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0},{status:2,statusname:'待分拣',product:'腿夹肉(去骨)',progress:'0/3',ordernum:40,unit:'斤',outstock:0}],
      }
      
    },
    methods: {
        expandIcon(props,data){
            if(data.isActive){
                return <div class="togglestyle"><a-icon type="minus-circle" class="icon"/>收起</div>
            }else{
                return <div class="togglestyle"><a-icon type="plus-circle" class="icon"/>展开</div>
            }
        },
      changeclassifyFun(e){
        console.log(e)  
      },
      go_detail(){
         this.$router.push('/sorting_print') 
      },
    }
  }
</script>

<style scoped lang="less">
    .bgBlue{
       background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
       border-radius: 5px; 
       border:none;
       font-size: 20px;
       font-family: PingFangSC-Semibold, PingFang SC;
       font-weight: 600;
       color: #FFFFFF;
        height:80px;
       outline:none;
    }
    .sorting-container{
        
    }
    .sortingformbox{
         background: #fff;
         display: flex;
         padding:25px;
         align-items: center;
         width:100%;
     }
     .rowsortingform{
         display:flex;
         justify-content: space-between;
             width: 100%;
     }
    .logo-top-img{
        width: 16%;
        height:80px;
        background: #FFFFFF;
        box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo-img{
            width:77.5%;
            
        }
    }
</style>